import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 19H11C11 20.1046 10.1046 21 9 21C7.8954 21 7 20.1046 7 19ZM18 17V18H0V17L2 15V8.99999C1.99921 5.90369 4.03273 3.17469 7 2.28999V1.99999C7 0.89539 7.8954 0 9 0C10.1046 0 11 0.89539 11 1.99999V2.28999C13.9673 3.17469 16.0008 5.90369 16 8.99999V15L18 17ZM14 8.99999C14 6.23859 11.7614 3.99999 9 3.99999C6.23858 3.99999 4 6.23859 4 8.99999V16H14V8.99999Z"
        fill="currentColor"
      />
    </svg>
  );
};
