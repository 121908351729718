import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7236 24.37C24.6997 22.5246 23.624 20.8548 21.9536 20.07V14.84C22.0124 11.2413 19.3941 8.15661 15.8336 7.63001C13.83 7.37605 11.8144 7.99983 10.3045 9.34111C8.79459 10.6824 7.93758 12.6105 7.95361 14.63V19.9C6.01006 20.5536 4.69628 22.3695 4.68361 24.42C4.69106 24.7447 4.83093 25.0523 5.07076 25.2714C5.31059 25.4904 5.62954 25.6019 5.95361 25.58H23.5336C23.8519 25.5774 24.1561 25.4484 24.3792 25.2215C24.6024 24.9946 24.7263 24.6883 24.7236 24.37ZM13.9536 9.68001C15.4249 9.37968 16.9532 9.7565 18.1163 10.7063C19.2793 11.6561 19.9539 13.0784 19.9536 14.58V19.58H9.95361V14.79C9.89943 12.3513 11.5732 10.213 13.9536 9.68001ZM6.84361 23.58C7.19811 22.391 8.29286 21.577 9.53361 21.58H19.9536C21.1933 21.5803 22.2863 22.393 22.6436 23.58H6.84361Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9536 6V5C15.9536 4.44772 15.5059 4 14.9536 4C14.4013 4 13.9536 4.44772 13.9536 5V6C13.9536 6.55228 14.4013 7 14.9536 7C15.5059 7 15.9536 6.55228 15.9536 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.13361 7.79C7.32138 7.97932 7.57698 8.0858 7.84361 8.0858C8.11025 8.0858 8.36585 7.97932 8.55361 7.79C8.94134 7.39997 8.94134 6.77004 8.55361 6.38L7.84361 5.67C7.65585 5.48069 7.40025 5.37421 7.13361 5.37421C6.86698 5.37421 6.61138 5.48069 6.42361 5.67C6.03589 6.06004 6.03589 6.68997 6.42361 7.08L7.13361 7.79Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.73361 12.19L5.73361 12.45C6.2585 12.5723 6.78588 12.2566 6.92608 11.7362C7.06629 11.2159 6.76889 10.678 6.25361 10.52L5.25361 10.26C4.72081 10.1203 4.17534 10.4377 4.03361 10.97C3.8932 11.4998 4.20538 12.0439 4.73361 12.19Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2036 12.52L25.2036 12.26C25.7189 12.102 26.0163 11.5641 25.8761 11.0438C25.7359 10.5234 25.2085 10.2077 24.6836 10.33L23.6836 10.59C23.1683 10.748 22.8709 11.2858 23.0111 11.8062C23.1514 12.3266 23.6787 12.6423 24.2036 12.52Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8436 7.79L22.5536 7.08C22.9413 6.68997 22.9413 6.06004 22.5536 5.67C22.3658 5.48069 22.1103 5.37421 21.8436 5.37421C21.577 5.37421 21.3214 5.48069 21.1336 5.67L20.4236 6.38C20.0359 6.77004 20.0359 7.39997 20.4236 7.79C20.6114 7.97932 20.867 8.0858 21.1336 8.0858C21.4003 8.0858 21.6558 7.97932 21.8436 7.79Z"
        fill="currentColor"
      />
    </svg>
  );
};
