import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path fill="#fff" d="M4 6H17V15H4z"></path>
      <path
        fill="#BDBDBD"
        d="M10 7.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 3.5a1 1 0 110-2 1 1 0 010 2zm0-3.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 3.5a1 1 0 110-2 1 1 0 010 2zm0-11C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm0 7.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 3.5a1 1 0 110-2 1 1 0 010 2z"
      ></path>
      <path
        fill="#fff"
        d="M5.197 14.764a.672.672 0 010-.95l8.613-8.578a.678.678 0 011.171.28.672.672 0 01-.217.67L6.15 14.764a.678.678 0 01-.954 0z"
      ></path>
      <path
        fill="#fff"
        d="M5.197 5.236a.678.678 0 01.954 0l8.613 8.578a.672.672 0 01-.282 1.167.678.678 0 01-.672-.217L5.197 6.186a.672.672 0 010-.95z"
      ></path>
    </svg>
  );
};
