import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.761719 4.78516H3.05664C3.13477 3.59375 3.94531 2.83203 5.20508 2.83203C6.43555 2.83203 7.25586 3.56445 7.25586 4.58008C7.25586 5.54688 6.8457 6.06445 5.63477 6.79688C4.28711 7.58789 3.7207 8.4668 3.80859 9.93164L3.81836 10.5957H6.08398V10.0391C6.08398 9.07227 6.44531 8.57422 7.72461 7.82227C9.05273 7.03125 9.79492 5.98633 9.79492 4.49219C9.79492 2.33398 8.00781 0.791016 5.33203 0.791016C2.43164 0.791016 0.839844 2.4707 0.761719 4.78516ZM5.05859 15.2539C6.05469 15.2539 6.67969 14.6387 6.67969 13.7012C6.67969 12.7539 6.05469 12.1387 5.05859 12.1387C4.08203 12.1387 3.4375 12.7539 3.4375 13.7012C3.4375 14.6387 4.08203 15.2539 5.05859 15.2539Z"
        fill="currentColor"
      />
    </svg>
  );
};
