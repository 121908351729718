import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#219653" />
      <path
        d="M6.58984 10.1821C8.03076 10.1821 8.86865 9.35693 8.86865 7.91602C8.86865 6.51318 8.00537 5.6626 6.58984 5.6626C5.18066 5.6626 4.31104 6.52588 4.31104 7.91602C4.31104 9.35059 5.15527 10.1821 6.58984 10.1821ZM6.58984 8.93164C6.22168 8.93164 6.00586 8.56348 6.00586 7.92236C6.00586 7.30029 6.23438 6.91309 6.58984 6.91309C6.94531 6.91309 7.16748 7.30029 7.16748 7.92236C7.16748 8.55713 6.95801 8.93164 6.58984 8.93164ZM7.80859 15L13.769 5.84033H12.0742L6.11377 15H7.80859ZM13.293 15.1143C14.7339 15.1143 15.5654 14.2827 15.5654 12.8418C15.5654 11.4453 14.7085 10.5884 13.293 10.5884C11.8838 10.5884 11.0142 11.4517 11.0142 12.8418C11.0142 14.2827 11.8521 15.1143 13.293 15.1143ZM13.293 13.8574C12.9185 13.8574 12.709 13.4956 12.709 12.8481C12.709 12.2324 12.9312 11.8452 13.293 11.8452C13.6484 11.8452 13.8706 12.2324 13.8706 12.8481C13.8706 13.4893 13.6611 13.8574 13.293 13.8574Z"
        fill="white"
      />
    </svg>
  );
};
