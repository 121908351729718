import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.125 4.5C5.19917 4.5 5.27167 4.52199 5.33334 4.5632C5.39501 4.6044 5.44307 4.66297 5.47145 4.73149C5.49984 4.80002 5.50726 4.87542 5.49279 4.94816C5.47833 5.0209 5.44261 5.08772 5.39016 5.14017C5.33772 5.19261 5.2709 5.22832 5.19816 5.24279C5.12542 5.25726 5.05002 5.24984 4.98149 5.22145C4.91297 5.19307 4.8544 5.14501 4.8132 5.08334C4.77199 5.02167 4.75 4.94917 4.75 4.875C4.75 4.77554 4.78951 4.68016 4.85983 4.60984C4.93016 4.53951 5.02554 4.5 5.125 4.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.875 9.75C17.9492 9.75 18.0217 9.77199 18.0833 9.8132C18.145 9.8544 18.1931 9.91297 18.2215 9.98149C18.2498 10.05 18.2573 10.1254 18.2428 10.1982C18.2283 10.2709 18.1926 10.3377 18.1402 10.3902C18.0877 10.4426 18.0209 10.4783 17.9482 10.4928C17.8754 10.5073 17.8 10.4998 17.7315 10.4715C17.663 10.4431 17.6044 10.395 17.5632 10.3333C17.522 10.2717 17.5 10.1992 17.5 10.125C17.5 10.0255 17.5395 9.93016 17.6098 9.85983C17.6802 9.78951 17.7755 9.75 17.875 9.75Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.75 1.5H3.25C2.42157 1.5 1.75 2.17157 1.75 3V12C1.75 12.8284 2.42157 13.5 3.25 13.5H19.75C20.5784 13.5 21.25 12.8284 21.25 12V3C21.25 2.17157 20.5784 1.5 19.75 1.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 10.5C13.1569 10.5 14.5 9.15685 14.5 7.5C14.5 5.84315 13.1569 4.5 11.5 4.5C9.84315 4.5 8.5 5.84315 8.5 7.5C8.5 9.15685 9.84315 10.5 11.5 10.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.25 6V15C24.25 15.3978 24.092 15.7794 23.8107 16.0607C23.5294 16.342 23.1478 16.5 22.75 16.5H6.25"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
