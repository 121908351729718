import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.86 4C18.2514 3.98879 18.624 4.16764 18.86 4.48L21.76 8.35C21.9185 8.5563 22.003 8.80988 22 9.07V14.8C22 15.4627 21.4627 16 20.8 16H18C17.9434 16.0048 17.8866 16.0048 17.83 16C17.4034 17.1937 16.2726 17.9904 15.005 17.9904C13.7374 17.9904 12.6066 17.1937 12.18 16H8.82C8.38937 17.1865 7.26218 17.9765 6 17.9765C4.73782 17.9765 3.61063 17.1865 3.18 16H3C1.34315 16 0 14.6569 0 13V3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V4H17.86ZM5 15C5 15.5523 5.44772 16 6 16C6.55228 16 7 15.5523 7 15C7 14.4477 6.55228 14 6 14C5.44772 14 5 14.4477 5 15ZM15 16C14.4477 16 14 15.5523 14 15C14 14.4477 14.4477 14 15 14C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16ZM18 14H20V9.33L17.5 6H16V10C16 10.5523 15.5523 11 15 11C14.4477 11 14 10.5523 14 10V3C14 2.44772 13.5523 2 13 2H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H3.18C3.61063 12.8135 4.73782 12.0235 6 12.0235C7.26218 12.0235 8.38937 12.8135 8.82 14H12.18C12.6066 12.8063 13.7374 12.0096 15.005 12.0096C16.2726 12.0096 17.4034 12.8063 17.83 14C17.8866 13.9952 17.9434 13.9952 18 14Z"
        fill="currentColor"
      />
    </svg>
  );
};
