import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1094 11.8359C21.6211 11.9648 21.8203 12.0977 21.8203 12.3906C21.8203 12.6914 21.5625 12.8867 21.1094 12.9336V11.8359ZM20.6719 10.5078C20.2188 10.3828 20.0039 10.2227 20.0039 9.94922C20.0039 9.67188 20.2344 9.47266 20.6719 9.42578V10.5078ZM21.1094 10.6055V9.4375C21.5 9.49609 21.7539 9.70703 21.8086 10.0742H23.0391C23 9.12109 22.293 8.49219 21.1094 8.41016V7.79688H20.6719V8.40625C19.5195 8.46484 18.6914 9.09375 18.6914 10.0781C18.6914 10.9609 19.2734 11.4336 20.3203 11.6641L20.6719 11.7383V12.9336C20.2148 12.8867 19.9492 12.6602 19.8828 12.3125H18.6484C18.6758 13.3164 19.4805 13.9062 20.6719 13.957V14.5508H21.1094V13.9531C22.3164 13.875 23.125 13.2383 23.125 12.2383C23.125 11.3633 22.5703 10.9258 21.4688 10.6836L21.1094 10.6055Z"
        fill="currentColor"
      />
      <path
        d="M20.8125 16.6875C23.8501 16.6875 26.3125 14.2251 26.3125 11.1875C26.3125 8.14993 23.8501 5.6875 20.8125 5.6875C17.7749 5.6875 15.3125 8.14993 15.3125 11.1875C15.3125 14.2251 17.7749 16.6875 20.8125 16.6875Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0462 19.5402C21.2317 20.4945 21.621 21.3975 22.1875 22.1875H5.6875C5.6875 22.1875 7.0625 20.0526 7.0625 14.625C7.0625 12.8016 7.78683 11.053 9.07614 9.76364C10.3655 8.47433 12.1141 7.75 13.9375 7.75V5.6875"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1023 24.9375C12.2188 25.3341 12.4605 25.6824 12.7914 25.9301C13.1224 26.1778 13.5246 26.3117 13.938 26.3117C14.3513 26.3117 14.7536 26.1778 15.0845 25.9301C15.4154 25.6824 15.6572 25.3341 15.7736 24.9375"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
