import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9136 26.0351H7.05363C5.39678 26.0351 4.05363 24.6919 4.05363 23.0351C4.00363 17.0351 4.00363 11.0351 4.00363 5.08508C3.97359 4.73319 4.13138 4.39155 4.41877 4.18627C4.70616 3.981 5.08051 3.94253 5.40363 4.08508C5.77945 4.24883 6.01726 4.62537 6.00363 5.03508V23.0351C5.99726 23.5305 6.35458 23.9559 6.84363 24.0351H25.0036C25.3609 24.019 25.6996 24.1947 25.8922 24.4961C26.0847 24.7975 26.1019 25.1787 25.9372 25.4961C25.7725 25.8136 25.4509 26.019 25.0936 26.0351H15.9136Z"
        fill="currentColor"
      />
      <path
        d="M7.71363 16.3051C7.71363 14.7751 7.71363 13.2351 7.71363 11.7051C7.7052 9.86622 8.80685 8.20388 10.5036 7.49508C12.2531 6.6125 14.3699 6.93786 15.7736 8.30508C16.7766 9.17757 17.3467 10.4458 17.3336 11.7751C17.3336 12.6651 17.3336 13.5551 17.3336 14.4451C17.3002 17.1076 19.1789 19.4118 21.7936 19.9151C22.1576 19.9737 22.5251 20.0071 22.8936 20.0151C23.2636 20.0151 23.6236 20.0151 23.9936 20.0151C24.5514 20.0123 25.0059 20.4623 25.0086 21.0201C25.0114 21.5779 24.5614 22.0323 24.0036 22.0351C23.2931 22.0651 22.5813 22.045 21.8736 21.9751C20.7844 21.854 19.7375 21.4845 18.8136 20.8951C16.9797 19.7567 15.7314 17.877 15.3936 15.7451C15.3338 15.3579 15.3038 14.9668 15.3036 14.5751C15.3036 13.6351 15.3036 12.6951 15.3036 11.7551C15.2769 10.2776 14.1103 9.07319 12.6344 8.9994C11.1585 8.9256 9.87761 10.0076 9.70363 11.4751C9.69368 11.6049 9.69368 11.7353 9.70363 11.8651V20.9951C9.70363 21.5474 9.25592 21.9951 8.70363 21.9951C8.15135 21.9951 7.70363 21.5474 7.70363 20.9951V16.3051H7.71363Z"
        fill="currentColor"
      />
    </svg>
  );
};
