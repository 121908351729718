import { UploadInvoiceImageField } from "@base86inc/apollo-client/build/src/components/UploadContent";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  AcceptInviteDocument,
  DeclineInviteDocument,
  PermissionsListDocument
} from "@base86inc/apollo-client";
import { useMutation, useQuery, useApolloClient } from "@apollo/react-hooks";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import React, { useState } from "react";
import Snackbar from "../../../components/Snackbar";
import uniq from "lodash/uniq";
import find from "lodash/fp/find";
import get from "lodash/fp/get";
import "firebase/auth";

const getPermissionsListData = get("permissionsList.data");
const getOrganizationName = get("organization.name");

export function PermissionsList() {
  const client = useApolloClient()
  const [acceptInvite, accept] = useMutation(AcceptInviteDocument, {
    update: () => client.resetStore()
  });
  const { data, loading, error } = useQuery(PermissionsListDocument);
  const permissions = getPermissionsListData(data) || [];
  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <List>
          {permissions.map(permission => (
            <MenuItem
              key={permission._id}
              disabled={accept.loading}
              onClick={() =>
                acceptInvite({ variables: { inviteId: permission._id } })
              }
            >
              <ListItemText primary={getOrganizationName(permission)} secondary={permission.primary ? "Active" : ""} />
            </MenuItem>
          ))}
        </List>
      )}
    </>
  );
}

export const ChangeOrganizationDrawer = ({
  changeOrgOpen,
  changeOrgControls,
  loading,
  error
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={changeOrgOpen}
      onClose={changeOrgControls.setFalse}
      onOpen={changeOrgControls.setTrue}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: "drawer-radius container" }}
    >
      <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
        <span className="drawer-notch"></span>Change Organization
      </DialogTitle>
      <DialogContent>
        <PermissionsList />
        <Snackbar message={error && error.message} variant="error" />
        <div style={{ paddingBottom: 120 }} />
      </DialogContent>
      <DialogActions className="bottom-actions">
        <Box pb={2.3} pl={2.3}>
          <Fab color="secondary" onClick={changeOrgControls.setFalse}>
            <CloseIcon />
          </Fab>
        </Box>
        <Box flexGrow={1} />
        {/* <Fab color="secondary" onClick={handleSubmit} disabled={loading}>
          {loading ? <CircularProgress size={16} /> : <CheckIcon />}
        </Fab> */}
      </DialogActions>
    </SwipeableDrawer>
  );
};
