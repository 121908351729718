import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import {
  useBooleanControls,
  OrganizationListDocument,
  InviteUserDocument
} from "@base86inc/apollo-client";
import React, { useState, useMemo } from "react";
import MainMenu, {
  PersistentMenu,
  usePersistentMenuStyles,
  FloatingButtonLeft,
  FloatingButtonRight
} from "../../../components/Menu";
import Nav from "../../../components/Nav";
import NavSearch from "../../../components/NavSearch";
import Fab from "@material-ui/core/Fab";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddIcon from "@material-ui/icons/Add";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UserListDisplay } from "./List";
import { InviteUserDrawer } from "./Invite";
import { ChangeOrganizationDrawer } from "./ChangeOrganization";
import get from "lodash/get"
import Fuse from "fuse.js";

const makeUserIndex = users => {
  return new Fuse(users, { keys: ["email"] });
};

export default () => {
  const classes = usePersistentMenuStyles();
  const { data, loading } = useQuery(OrganizationListDocument)
  const [inviteUser, invite] = useMutation(InviteUserDocument)
  const [searchText, setSearchText] = useState("");
  const [changeOrgOpen, changeOrgControls] = useBooleanControls(false);
  const [inviteOpen, inviteControls] = useBooleanControls(false);
  const [filterOpen, filterControls] = useBooleanControls(false);
  const users = get(data, 'organizationList.data[0].accessControls')
  const userIndex = useMemo(() => makeUserIndex(users), [users]);
  const filteredUsers = searchText
    ? userIndex.search(searchText)
    : users;
  const onSubmit = async (emails) => {
    for (const email of emails) {
      await inviteUser({
        variables: { email, permissions: defaultPermissions },
        refetchQueries: [{ query: OrganizationListDocument }],
        update: inviteControls.setFalse
      })
    }
  }
  return (
    <>
      <Box className={classes.mainContainer}>
        <Nav title="Users">
          <NavSearch
            searchText={searchText}
            setSearchText={setSearchText}
            filterOpen={filterOpen}
            filterControls={filterControls}
          />
        </Nav>
        {loading && <LinearProgress />}
        <UserListDisplay rows={filteredUsers} onChangeOrganization={changeOrgControls.setTrue}  />
        <div style={{ paddingBottom: 120 }} />
        <FloatingButtonLeft>
          <MainMenu />
        </FloatingButtonLeft>
        <FloatingButtonRight>
          <Fab color="secondary" onClick={inviteControls.setTrue}>
            <AddIcon />
          </Fab>
        </FloatingButtonRight>
      </Box>
      <InviteUserDrawer inviteOpen={inviteOpen} inviteControls={inviteControls} {...invite} onSubmit={onSubmit} />
      <ChangeOrganizationDrawer changeOrgOpen={changeOrgOpen} changeOrgControls={changeOrgControls} {...invite} onSubmit={onSubmit} />
    </>
  );
};

const defaultPermissions = {
  readUser: true,
  writeUser: true,
  readInvoice: true,
  writeInvoice: true,
  readVendor: true,
  writeVendor: true,
  readProduct: true,
  writeProduct: true,
  readBilling: true,
  writeBilling: true,
}