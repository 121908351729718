import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7 10.7233C15.7148 9.14504 16.5067 6.46241 15.6719 4.04297C14.8371 1.62353 12.5594 0 10 0C7.44059 0 5.16294 1.62353 4.32814 4.04297C3.49333 6.46241 4.28517 9.14504 6.3 10.7233C2.49957 12.2369 0.00422151 15.9126 0 20.0033V21.0033C0 21.5556 0.447715 22.0033 1 22.0033C1.55228 22.0033 2 21.5556 2 21.0033V20.0033C2 15.5851 5.58172 12.0033 10 12.0033C14.4183 12.0033 18 15.5851 18 20.0033V21.0033C18 21.5556 18.4477 22.0033 19 22.0033C19.5523 22.0033 20 21.5556 20 21.0033V20.0033C19.9958 15.9126 17.5004 12.2369 13.7 10.7233ZM6 6.00335C6 3.79421 7.79086 2.00335 10 2.00335C12.2091 2.00335 14 3.79421 14 6.00335C14 8.21249 12.2091 10.0033 10 10.0033C8.93913 10.0033 7.92172 9.58192 7.17157 8.83177C6.42143 8.08163 6 7.06421 6 6.00335Z"
        fill="currentColor"
      />
    </svg>
  );
};
