import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7381 5.11891H20.6881C21.0607 5.11639 21.4133 5.28707 21.6424 5.58085C21.8716 5.87462 21.9513 6.25817 21.8581 6.61891L18.9481 18.2089C18.8122 18.74 18.3363 19.1134 17.7881 19.1189H4.11812C3.56623 19.1179 3.08496 18.7436 2.94812 18.2089L0.0381232 6.61891C-0.0550291 6.25817 0.0246627 5.87462 0.253808 5.58085C0.482954 5.28707 0.835557 5.11639 1.20812 5.11891H5.38812C5.40204 5.06028 5.42215 5.00329 5.44812 4.94891L7.94812 0.618914C8.0969 0.257984 8.44211 0.0166295 8.83217 0.00082407C9.22224 -0.0149813 9.58585 0.197653 9.76334 0.545362C9.94082 0.893072 9.89974 1.31228 9.65812 1.61891L7.65812 5.11891H14.4481L12.4481 1.61891C12.2511 1.30892 12.2398 0.915852 12.4188 0.595101C12.5978 0.27435 12.9383 0.0775585 13.3056 0.0825299C13.6729 0.0875013 14.0079 0.293435 14.1781 0.618914L16.6781 4.94891C16.7041 5.00329 16.7242 5.06028 16.7381 5.11891ZM4.72812 17.1189H17.1681L19.6681 7.11891H2.22812L4.72812 17.1189Z"
        fill="currentColor"
      />
    </svg>
  );
};
