import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.14 11.1816L9 7.08159L15.49 0.581585C16.2701 -0.193862 17.5299 -0.193862 18.31 0.581585L19.75 1.99159C20.1256 2.36672 20.3366 2.87577 20.3366 3.40659C20.3366 3.9374 20.1256 4.44645 19.75 4.82159L13.24 11.3216L9.14 11.1816ZM16.9 2.23159C16.8437 2.22207 16.7863 2.22207 16.73 2.23159H16.71C16.6459 2.25517 16.5878 2.29275 16.54 2.34159L11 7.88159L11.1 9.20159L12.46 9.25159L18 3.71159C18.0947 3.6177 18.1479 3.4899 18.1479 3.35659C18.1479 3.22327 18.0947 3.09547 18 3.00159L17.29 2.29159C17.2605 2.26581 17.2266 2.24549 17.19 2.23159H17.13H17H16.9Z"
        fill="currentColor"
      />
      <path
        d="M21 6.9917C20.4477 6.9917 20 7.43941 20 7.9917V15.9917C20 16.544 19.5523 16.9917 19 16.9917H3C2.44772 16.9917 2 16.544 2 15.9917V5.9917C2 5.43941 2.44772 4.9917 3 4.9917H7C7.55228 4.9917 8 4.54398 8 3.9917C8 3.43941 7.55228 2.9917 7 2.9917H3C1.34315 2.9917 0 4.33484 0 5.9917V15.9917C0 17.6486 1.34315 18.9917 3 18.9917H19C20.6569 18.9917 22 17.6486 22 15.9917V7.9917C22 7.43941 21.5523 6.9917 21 6.9917Z"
        fill="currentColor"
      />
    </svg>
  );
};
