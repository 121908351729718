import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 14.1C22.4477 14.1 22 14.5477 22 15.1V20C22 20.5523 21.5523 21 21 21H11C10.4477 21 10 20.5523 10 20V7.00002C10 6.44774 10.4477 6.00002 11 6.00002H16C16.5523 6.00002 17 5.55231 17 5.00002C17 4.44774 16.5523 4.00002 16 4.00002H11C9.72441 3.99511 8.58519 4.79737 8.16 6.00002H8C6.34315 6.00002 5 7.34317 5 9.00002V23C5 24.6569 6.34315 26 8 26H18C19.6569 26 21 24.6569 21 23C22.6569 23 24 21.6569 24 20V15.1C24 14.5477 23.5523 14.1 23 14.1ZM18 24H8C7.44772 24 7 23.5523 7 23V9.00002C7 8.44774 7.44772 8.00002 8 8.00002V20C8 21.6569 9.34315 23 11 23H19C19 23.5523 18.5523 24 18 24Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.73 6.00004L23.32 4.59004C22.9449 4.21449 22.4358 4.00347 21.905 4.00347C21.3742 4.00347 20.8651 4.21449 20.49 4.59004L13.98 11.09L14.12 15.19L18.23 15.33L24.73 8.83004C25.1056 8.45491 25.3166 7.94586 25.3166 7.41504C25.3166 6.88423 25.1056 6.37518 24.73 6.00004ZM22.96 7.77004L17.43 13.31L16.06 13.26L16.01 11.89L21.55 6.35004C21.5966 6.3045 21.6508 6.26729 21.71 6.24004H21.89H21.96H22.08H22.15C22.1854 6.25631 22.219 6.27643 22.25 6.30004L22.95 7.01004C23.0623 7.10357 23.1281 7.24157 23.1301 7.38774C23.132 7.53391 23.0698 7.6736 22.96 7.77004Z"
        fill="currentColor"
      />
    </svg>
  );
};
