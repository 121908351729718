import React from "react";

export default ({ style }) => {
  return (
    <svg
      style={style}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="6" width="13" height="9" fill="white" />
      <path
        d="M10 7.5C8.6193 7.5 7.5 8.6193 7.5 10C7.5 11.3807 8.6193 12.5 10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.6193 11.3807 7.5 10 7.5ZM10 11C9.4477 11 9 10.5523 9 10C9 9.4477 9.4477 9 10 9C10.5523 9 11 9.4477 11 10C11 10.5523 10.5523 11 10 11ZM10 7.5C8.6193 7.5 7.5 8.6193 7.5 10C7.5 11.3807 8.6193 12.5 10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.6193 11.3807 7.5 10 7.5ZM10 11C9.4477 11 9 10.5523 9 10C9 9.4477 9.4477 9 10 9C10.5523 9 11 9.4477 11 10C11 10.5523 10.5523 11 10 11ZM10 0C4.47715 0 0 4.4772 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.4772 15.5228 0 10 0ZM10 14C7.37184 14.0136 4.99827 12.4312 4 10C5.38071 6.6863 9.1863 5.1193 12.5 6.5C14.0826 7.1594 15.3406 8.4174 16 10C15.0017 12.4312 12.6282 14.0136 10 14ZM10 7.5C8.6193 7.5 7.5 8.6193 7.5 10C7.5 11.3807 8.6193 12.5 10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.6193 11.3807 7.5 10 7.5ZM10 11C9.4477 11 9 10.5523 9 10C9 9.4477 9.4477 9 10 9C10.5523 9 11 9.4477 11 10C11 10.5523 10.5523 11 10 11Z"
        fill="#2D9CDB"
      />
    </svg>
  );
};
